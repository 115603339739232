/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@theme-ui/components'
import { FC } from 'react'

export const CommentChildren: FC = (props) => {
  return (
    <Box sx={{ borderLeft: '1px solid', borderColor: 'rgba(0,0,0,0.1)' }}>
      {props.children}
    </Box>
  )
}