import React from 'react'

import { CompiledComment } from "./models/comments"
import { Comment } from "./comment"

export const render =
  (comments: { [key: string]: CompiledComment }) => {
    const renderer = (comment: CompiledComment, depth: number = 0) => {
      return <Comment key={comment.uid} {...comment} depth={depth}>
        {comment.children.map(rc => {
          return renderer(comments[rc], depth + 1)
        })}
      </Comment>
    }
    return renderer
  }
