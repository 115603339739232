
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@theme-ui/components'
import { FC } from 'react'
import { render } from './render'
import { useLiveComments } from './hooks/useLiveComments'
import { CommentForm } from './commentForm'

export interface CommentsProps {
  doc: string
}

export const Comments: FC<CommentsProps> = (props: any) => {
  const { doc } = props
  const { comments, rootCommentIds } = useLiveComments(doc)
  const commentRenderer = render(comments)

  return (
    <Box>
      <Box>
        {rootCommentIds.map((rc) => commentRenderer(comments[rc], 0))}
      </Box>
      <Box>
        <CommentForm />
      </Box>
    </Box>
  )
}