/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Heading, Container, Grid, Flex, Button, Text, Textarea } from '@theme-ui/components'
import { useState } from 'react'
import { Link as RouteLink } from 'gatsby'
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { app } from 'firebase';

import circ from '../../images/cd.svg';
import like from '../../images/like-match.svg';
import star from '../../images/star.svg'
import { Comments } from '../../../plugins/gatsby-firebase-comments'

export const Wallpaper = (props: any) => {
  const [image, setImage] = useState<any>(undefined);
  const [comments, setComments] = useState<any>({})
  const [rootComments, src] = useState<any[]>([])

  const { id } = props
  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .collection("wallpapers")
      .doc(id)
      .get()
      .then((wall) => {
        setImage(wall)
      })
  }, []);

  if (!image) {
    return null
  }

  const {
    name,
    images,
    url,
    category,
    description,
    tags,
    colors
  } = image.data()

  return (
    <Container>
      <Grid columns={[1, 2, 3]} py={3} gap={3} >
        <Box>
          <Box sx={{ position: ["relative", "sticky"], top: [0, 3] }}>
            <img sx={{ width: '100%' }} src={images?.full?.url || ""} />
            <Flex sx={{ alignItems: 'center', flex: 1, p: 1 }}>
              <Flex as={RouteLink} to={`/categories/${category}`} sx={{ alignItems: 'center', pr: 3, flex: 1 }}>
                <img width="24" height="24" sx={{ mr: 2 }} src={circ} />
                {category}
              </Flex>
              <Flex>
                <img width="24" height="24" src={like} sx={{ marginRight: 2 }} />
                <img width="24" height="24" src={star} sx={{ marginRight: 2 }} />
              </Flex>
            </Flex>
            <Box p={1} pt={2}>
              <Button as="a" download={`${name}.jpg`} href={url} target="_blank" >Download</Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ gridColumnStart: [1, 2], gridColumnEnd: [2, 4], backgroundColor: "background" }}>
          <Box p={2}>
            <Heading sx={{ fontSize: 6 }}>{name}</Heading>
            <Box>
              <Text>{description}</Text>
            </Box>
          </Box>

          <Box p={2} mt={3}>
            <Heading as="h4" sx={{ mb: 2 }}>Tags</Heading>
            <Flex as="ul" sx={{ listStyle: 'none', flexWrap: 'wrap', }}>
              {tags.map((t: string) => {
                return <li key={t} sx={{ mr: 2, backgroundColor: 'muted', borderRadius: 4, p: 2, px: 4, mb: 2 }}>{t}</li>
              })}
            </Flex>
          </Box>

          <Box p={2} mt={3}>
            <Heading as="h4" sx={{ mb: 2 }}>Core Colours</Heading>
            <Grid columns={[2, 2, 3]} sx={{ width: '100%' }}>
              {(colors || []).map((c: any, i: number) => {
                const L = 0.299 * c.color.red + 0.587 * c.color.green + 0.114 * c.color.blue
                let textColor = '#ffffff'

                if (L > 186) {
                  textColor = '#000'
                }
                return <Flex key={i} sx={{
                  height: 48,
                  borderRadius: 4,
                  width: '100%',
                  color: textColor,
                  fontSize: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: `rgb(${c.color.red}, ${c.color.green}, ${c.color.blue})`
                }}>
                  {`rgb(${c.color.red}, ${c.color.green}, ${c.color.blue})`}
                </Flex>
              })}
            </Grid>
          </Box>

          <Box p={2} mt={3}>
            <Heading as="h4">Comments</Heading>

            <Comments doc={`wallpapers/${id}`} />
          </Box>
        </Box>
      </Grid>
    </Container >
  )
}