/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Heading, Container } from '@theme-ui/components'
import { ImageGrid } from '../../containers/imageGrid'

export const Wallpapers = (props: any) => {
  return (
    <Container>
      <Box py={5}>
        <ImageGrid />
      </Box>
    </Container>
  )
}