/** @jsx jsx */
import { jsx } from 'theme-ui'

import Image from "../components/image"
import { SEO } from "../components/seo"

import { Heading, Text, Message, Grid, Box, Container, Flex } from '@theme-ui/components'
import { Jumbo } from "../components/molecules/jumbo"
import { List } from "../components/molecules/list"

import GatsbyImage from "gatsby-image"
import { graphql, Link } from "gatsby"
import { Router } from '@reach/router'
import { Wallpaper } from '../components/templates/wallpaper'
import { Wallpapers } from '../components/templates/wallpapers'
import { Subscribe } from '../containers/subscribe'

const WallpaperPage = (props: any) => {
  return (
    <Flex>
      <Box sx={{ flex: 1 }}>
        <Router>
          <Wallpapers path="/wallpapers" />
          <Wallpaper path="/wallpapers/:id" />
        </Router>
      </Box>
    </Flex>
  )
}

export default WallpaperPage