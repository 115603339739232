/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@theme-ui/components'
import { CommentChildren } from './commentChildren'
import { CommentResponses } from './commentResponses'
import { CommentContent } from './commentContent'
import { CommentHeader } from './commentHeader'
import { FirebaseComment } from './models/comments'
import { FC } from 'react'

export const Comment: FC<FirebaseComment & { depth: number }> = (props) => {
  const { depth = 0, comment } = props

  return (
    <Box py={2} px={depth === 0 ? 0 : 4}>
      <Box py={2}>
        <CommentHeader {...props} />
        <CommentContent>{comment}</CommentContent>
        <CommentResponses />
      </Box>
      <CommentChildren>
        {props.children}
      </CommentChildren>
    </Box>
  )
}