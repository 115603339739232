

/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Flex } from '@theme-ui/components'
import { FC } from 'react'
import { Comment } from './models/comments'

export const CommentHeader: FC<Comment> = ({ user, createdAt }) => {
  return (
    <Flex sx={{ fontSize: 1, }}>
      <Box sx={{ fontWeight: 'bold', mr: 2 }}>{user.name}</Box>
      <Box sx={{}}>{createdAt.toDate().toLocaleString()}</Box>
    </Flex>
  )
}