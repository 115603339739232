import { useState } from 'react'
import { Comment, FirebaseComment, CompiledComment } from '../models/comments'
import { useFirebase } from 'gatsby-plugin-firebase'
import { app } from 'firebase';

export function useLiveComments(doc: string) {
  const [comments, setComments] = useState<{ [key: string]: CompiledComment }>({})
  const [rootCommentIds, setRootCommentIds] = useState<string[]>([])

  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .doc(doc)
      .collection("comments")
      .orderBy("createdAt", "desc")
      .limit(100)
      .orderBy("cid", "asc")
      .onSnapshot((data) => {
        const tree = {} as { [key: string]: CompiledComment }
        const roots = [] as string[]
        data.docs.forEach(d => {
          tree[d.id] = { ...{ ...d.data() as Comment, uid: d.id, fref: d.ref }, children: [] }
        })
        data.docs.forEach(d => {
          const dd = d.data()
          if (!dd.parent) {
            roots.push(d.id)
          } else {
            tree[dd.parent].children.push(d.id)
          }
        })

        setComments(tree)
        setRootCommentIds(roots)
      })
  }, []);

  return { comments, rootCommentIds }
}