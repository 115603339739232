

/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex } from '@theme-ui/components'

export const CommentResponses = () => {

  return (
    <Flex sx={{ fontSize: 1 }}>
      <span>Reply</span>
      <span sx={{ px: 2 }}>&middot;</span>
      <span>Report</span>
    </Flex>
  )
}