/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Button, Textarea } from '@theme-ui/components'

export const CommentForm = () => {
  return (
    <Box mt={3}>
      <Textarea sx={{ fontSize: 2 }} placeholder={"Leave a comment"}></Textarea>
      <Box my={2} sx={{ textAlign: 'right' }}>
        <Button>comment</Button>
      </Box>
    </Box>
  )
}